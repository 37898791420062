import { Container } from 'reactstrap';

import '@inmomatch-app/shared/css/style.css';
import 'styles/Pricing.css';
import { Header, PricingTable } from 'components';
import { LoginBackground } from 'assets';

const PricingPage = () => {
    return (
        <Container id='pricing-page' fluid className='p-0'>
            <Header />
            <Container fluid id='background' 
                className='p-0 d-flex align-items-center'
                style={{backgroundImage: `url(${LoginBackground})`}}
            >
                <PricingTable />
            </Container>
        </Container>
    )
}

export default PricingPage;