import { useEffect, useState } from 'react';
import { Footer, Header, ImageCarousel, PropertiesDefault, PropertyCardList } from 'components';
import { Container } from 'reactstrap';

import { PageLoader } from 'pages';

import '@inmomatch-app/shared/css/style.css';

const imagelist = [
    {'src' :'https://cdn.wowowhome.com/photos/2018/12/modern-cliff-house-by-mccall-design-and-planning-38_2.jpg'},
    {'src' :'https://www.darchitectdrawings.com/wp-content/uploads/2019/06/Brothers-Front-facade.jpg'},
    {'src' :'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.sherwin-williams.com%2Fwcm%2Fidc%2Fgroups%2Fpublic%2F%40swpublic%2F%40sherwin-williams%2F%40content%2Fdocuments%2Fwebcontent%2Fmdaw%2Fmdq5%2F~edisp%2Fsw-img-warm-neutral-sw07020159.jpg&f=1&nofb=1&ipt=4e04072747bd96b0e08c05215864dc0aa9ce5bf8c820bf336ab8b4a422189e25&ipo=images'},
    {'src' :'https://markstewart.com/wp-content/uploads/2021/01/MODERN-RUSTIC-FARMHOUSE-AMERICAN-FARM-FRESH-MF-3168-FRONT-VIEW-scaled.jpg'}
]

const Home = () => {
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(false);
    },[])

    if(loading) return <PageLoader />

    return (
        <div className="homePage">
            <Header />
            <ImageCarousel imageList={imagelist}/>
            <span className='px-5 fs-1 fw-bold prevent-select'>Propiedades Recomendadas</span>
            <Container fluid className='p-5 py-0'>
                <PropertyCardList properties={PropertiesDefault}/>
            </Container>
            <span className='px-5 fs-1 fw-bold prevent-select'>Propiedades Vistas Recientemente</span>
            <Container fluid className='p-5 py-0'>
                <PropertyCardList properties={PropertiesDefault}/>
            </Container>
            <Footer />
        </div>
    )
}

export default Home;