import { FC, FormEvent, useEffect, useState } from "react";
import { Button, Container, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalHeader } from "reactstrap";

import { PortalView } from "@inmomatch-app/shared/interface";

import '@inmomatch-app/shared/css/style.css';
import 'styles/Portal.css';

interface PortalModalAddProps extends PortalView {
    isOpen: boolean,
    toggle: () => void;
}

const PortalModalAdd:FC<PortalModalAddProps> = ({portalView, isOpen, toggle}) => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const [valid, setValid] = useState<boolean | undefined>();
    
    useEffect(() => {
        setValid(password.length > 0 && passwordConfirm.length > 0 && password === passwordConfirm)
    },[password, passwordConfirm]);

    const formSubmit = (event: FormEvent) => {
        event.preventDefault();
        console.log(name, email, password, passwordConfirm);
    }

    return (
        <Modal centered id='modal-add-user' isOpen={isOpen} toggle={toggle} size='md'>
            <ModalHeader>Agregar usuario</ModalHeader>
            <Form className='p-3' onSubmit={formSubmit}>
                <FormGroup floating>
                    <Input
                        className='no-focus'
                        id='name'
                        name='name'
                        placeholder='Nombre de usuario:'
                        type='text' 
                        onChange={e => setName(e.target.value)}
                    />
                    <Label for='name'>
                        Nombre de usuario:
                    </Label>
                </FormGroup>
                <FormGroup floating>
                    <Input
                        className='no-focus'
                        id='email'
                        name='email'
                        placeholder='Correo electrónico:'
                        type='email'
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Label for='email'>
                        Correo electrónico
                    </Label>
                </FormGroup>
                <FormGroup floating>
                    <Input
                        className='no-focus'
                        id='password'
                        name='password'
                        placeholder='Contraseña:'
                        type='password'
                        onChange={ e => setPassword(e.target.value)}
                    />
                    <Label for='password'>
                        Contraseña:
                    </Label>
                </FormGroup>
                <FormGroup floating>
                    <Input
                        className='no-focus'
                        id='password-confirm'
                        name='password-confirm'
                        placeholder='Confirmar contraseña:'
                        type='password'
                        onChange={ e => setPasswordConfirm(e.target.value)}
                        valid={valid}
                    />
                    <Label for='password-confirm'>
                        Confirmar contraseña:
                    </Label>
                    <FormFeedback>
                        Las contraseñas no coinciden
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for='type'>
                        Tipo:
                    </Label>
                    <Input
                        type='select'
                        id='type'
                        name='type'
                        disabled={true}
                        value={portalView === 'agents' ? 'Agente' : 'Administrador'}
                    >
                        {
                            portalView === 'agents' ?
                            <option>
                                Agente Inmobiliario
                            </option>
                            :
                            <option>
                                Administrador
                            </option>
                        }
                    </Input>
                </FormGroup>
                <Container fluid 
                    className='p-0 d-flex justify-content-end align-items-center prevent-select'
                >
                    <span id='cancel' onClick={toggle}
                        className='text-danger me-3'
                    >
                        Cancelar
                    </span>
                    <Button id='btn-add' color='primary' type='submit'
                        className='align-self-end text-light fw-bold'
                    >
                        Agregar
                    </Button>
                </Container>
            </Form>
        </Modal>
    )
}

export default PortalModalAdd;