import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons"

import { UserContext, UserContextType } from "context";

import '@inmomatch-app/shared/css/style.css';

const UserDropdown = (): JSX.Element => {
    const { logout, userDoc } = useContext(UserContext) as UserContextType;
    const navigate = useNavigate();

    const signOut = async ()=> {
        await logout();
        navigate('/login');
    }

    return (
        <UncontrolledDropdown id='user-dropdown' className='p-0 m-0'>
            <DropdownToggle
                className='no-focus'
                id='button-toggle'
            >
                <FontAwesomeIcon icon={faUser} 
                    className='text-light fs-2'
                />
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={_=>navigate('/edit-profile')}>
                    Editar Perfil
                </DropdownItem>
                <DropdownItem onClick={_=>navigate('/inventory')}>
                    Ver Propiedades
                </DropdownItem>
                {userDoc && userDoc.userType < 3 ?
                    <DropdownItem onClick={_=>navigate('/agents')}>
                        Ver Agentes
                    </DropdownItem>
                    :
                    ''
                }
                {userDoc && userDoc.userType < 2 ?
                    <DropdownItem onClick={_=>navigate('/admins')}>
                        Ver Administradores
                    </DropdownItem>
                    :
                    ''
                }
                <DropdownItem>
                    <span className='text-danger' onClick={_=>signOut()}>
                        Cerrar sessión
                    </span>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default UserDropdown;