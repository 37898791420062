import { useState } from "react";
import { Button, Container, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";

import { ModalProps } from "@inmomatch-app/shared/interface";

interface PropertyTypeModalFormProps extends ModalProps {
    setType: (propertyType: string) => void;
}

const PropertyTypeModalForm = ({isOpen, toggle, setType}: PropertyTypeModalFormProps):JSX.Element => {
    const [ propertyType, setPropertyType ] = useState<string>('');
    const [valid, setValid] = useState<boolean>(true);

    const options = [
        '',
        'Casa',
        'Departamento',
        'Oficina',
        'Local Comercial',
        'Terreno',
        'Bodega'
    ]

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setType(propertyType);
        toggle();
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setValid(value.length > 0);
        setPropertyType(value);
    }

    return (
        <div>
            <Modal
                centered
                size='md'
                isOpen={isOpen}
                toggle={toggle}
                backdrop='static'
                keyboard={false}
            >
                <ModalHeader>
                    Agregar una propiedad
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <FormGroup>
                            <Label
                                for='property-type'
                            >
                                Tipo de Propiedad:
                            </Label>
                            <Input
                                id='property-type'
                                type='select'
                                onChange={handleChange}
                                invalid={!valid}
                            >
                                {
                                    options.map((option, index) => {
                                        return(
                                            <option key={index}>
                                                {option}
                                            </option>
                                        )
                                    })
                                }
                            </Input>
                            <FormFeedback>
                                Seleccione un tipo
                            </FormFeedback>
                        </FormGroup>
                        <Container
                            fluid
                            className='text-end p-0'
                        >
                            <Button
                                color='danger'
                                className='me-2'
                                onClick={toggle}
                            >
                                Cancelar
                            </Button>
                            <Button
                                color='primary'
                                className='text-light'
                                type='submit'
                                disabled={propertyType.length === 0}
                            >
                                Continuar
                            </Button>
                        </Container>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default PropertyTypeModalForm;