import { PropertyObject } from "@inmomatch-app/shared/interface";
import { Col, Container, Row } from "reactstrap";
import { Header, PropertyCard } from "components";

import '@inmomatch-app/shared/css/style.css';
import { useEffect, useState } from "react";

type SearchResultsPageProps = {
    properties: PropertyObject[];
}

const getName = (property: PropertyObject):string => {
    return property.location.split(',')[1];
}

const SearchResultsPage = ({properties}: SearchResultsPageProps) => {
    const [results] = useState(() => properties.length);

    useEffect(() => {
        console.log(properties);
    },[properties]);

    return (
        <Container fluid className='p-0'>
            <Header />
            <span className='px-4 text-primary fs-1 fw-bold'>
                Encuentra tu match ideal
            </span>
            <br/>
            <span className='px-4 fs-4'>
                {results} resultados de búsqueda 
            </span>
            <Row
                className='px-3'
            >
                {properties.map((property, index) => (
                    <Col
                        md={3}
                        key={index}
                    >
                        <PropertyCard
                            id={property._id}
                            type={property.propertyType}
                            title={getName(property)}
                            image={property.images[0]}
                            bedrooms={property.bedrooms ?? 0}
                            bathrooms={property.bathrooms ?? 0}
                            parking={property.parkingSpots ?? 0}
                            price={property.price.toLocaleString()}
                            description={property.description}
                        />
                    </Col>
                ))}
            </Row>
        </Container>
    )
}

export default SearchResultsPage;