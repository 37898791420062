import { Table } from "reactstrap";

import { PropertyObject } from "@inmomatch-app/shared/interface";

import PortalTableOptions from "./PortalTableOptions";

import '@inmomatch-app/shared/css/style.css';
import 'styles/Portal.css';

type TablePropertiesProps = {
    properties: PropertyObject[];
    handleEdit: (property: PropertyObject) => void;
    handleDelete: (property: PropertyObject) => void;
}

const TableProperties = ({properties, handleEdit, handleDelete}: TablePropertiesProps): JSX.Element => {
    return (
        <Table
            hover
            striped
        >
            <thead>
                <tr>
                    <th>
                        Nombre   
                    </th>
                    <th>
                        Descripción
                    </th>
                    <th>
                        Tipo
                    </th>
                    <th>
                        Estado
                    </th>
                    <th>
                        Opciones
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    properties.map((property, index) => (
                        <tr key={index}>
                            <td>
                                {property.location.split(',')[1]}
                            </td>
                            <td>
                                {property.description}
                            </td>
                            <td>
                                {property.propertyType}
                            </td>
                            <td>
                                {property.adquisitionType}
                            </td>
                            <td>
                                <PortalTableOptions
                                    property={property}
                                    handleEdit={handleEdit}
                                    handleDelete={handleDelete}
                                />
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    )
}

export default TableProperties;