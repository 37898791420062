import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { FormGroup, Input, Label, Button, Row, Col } from "reactstrap";

import 'styles/PropertyModalForm.css';

interface Props {
  id: string;
  name: string;
  label: string;
  onChange?: (files: FileList) => void;
  initialImages: File[];
}

const ImageInput: React.FC<Props> = ({ id, name, label, onChange, initialImages }) => {
  const [images, setImages] = useState<File[]>(initialImages);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList || fileList.length === 0) return;

    // Convert FileList to array of File objects
    const filesArray = Array.from(fileList) as File[];

    // Update state with new images
    setImages([...images, ...filesArray]);

    // Call onChange with new FileList object
    if (onChange) {
      const newFileList = new DataTransfer();
      images.forEach((file) => {
        newFileList.items.add(file);
      });
      filesArray.forEach((file) => {
        newFileList.items.add(file);
      });
      onChange(newFileList.files);
    }
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    // Call onChange with new FileList object
    if (onChange) {
      const newFileList = new DataTransfer();
      newImages.forEach((file) => {
        newFileList.items.add(file);
      });
      onChange(newFileList.files);
    }
  };

  return (
    <FormGroup>
      <Label for={id}>{label}</Label>
      <Row className='overflow-scroll pt-3 images-row'>
        {images.map((image, index) => (
          <Col key={index} md={6} className="mb-3 img-preview">
            <div className="position-relative">
              <div className="ratio ratio-16x9">
                <img
                  src={URL.createObjectURL(image)}
                  alt={`${index}`}
                  className="img-fluid"
                />
              </div>
                
              <Button
                size="sm"
                color="danger"
                className="position-absolute top-0 start-100 translate-middle rounded"
                onClick={() => handleRemoveImage(index)}
              >
                {/*x to delete*/}
                <FontAwesomeIcon icon={faX} size="2xs"/>
              </Button>
            </div>
          </Col>
        ))}
        <Col md={6} className="mb-3 add-img-btn">
          <Label htmlFor={id} className="ratio ratio-16x9 position-relative btn btn-lg btn-light btn-block">
            <FontAwesomeIcon 
            icon={faPlus} 
            size="2xl"
            className="h-50 position-absolute top-50 start-50 translate-middle" />
          </Label>
          <Input
            title="Add image"
            type="file"
            id={id}
            name={name}
            multiple
            onChange={handleOnChange}
            hidden
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

export default ImageInput;
