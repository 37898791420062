import React from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';

import '@inmomatch-app/shared/css/style.css';

type Option = {
  value: string;
  label: string;
};

type SelectInputRowProps = {
  label: string;
  name: string;
  value: string;
  options: Option[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const SelectInputRow = ({ label, name, value, options, onChange }: SelectInputRowProps) => {
  return (
    <FormGroup row>
      <Label htmlFor={name} sm={2}>
        {label}
      </Label>
      <Col sm={10}>
        <Input type="select" id={name} name={name} value={value} onChange={onChange}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
      </Col>
    </FormGroup>
  );
};

export default SelectInputRow;
