import React, { ChangeEventHandler } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

import '@inmomatch-app/shared/css/style.css';

interface Props {
  label: string;
  name: string;
  value: any;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const PropertyInputRow: React.FC<Props> = ({ label, name, value, onChange }) => {
  return (
    <FormGroup row>
      <Label for={name} sm={2}>
        {label}
      </Label>
      <Input
        type="text"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className="col-sm-10"
      />
    </FormGroup>
  );
};

export default PropertyInputRow;
