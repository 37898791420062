import { Header, LoginForm } from "components";
import { Container } from "reactstrap";

import { LoginBackground } from "assets";

import '@inmomatch-app/shared/css/style.css';
import 'styles/Login.css';

const Login = () => {
    return (
        <div className="loginPage">
            <Header />
            <Container
                fluid
                className="d-flex align-items-center background-container"
                style={{backgroundImage: `url(${LoginBackground})`}}
            >
                <LoginForm />
            </Container>
        </div>
    )
}
export default Login;