import React, { useState } from 'react';
import SearchInput from './SearchInput';
import SearchResultList, { SearchResult } from './SearchResultList';
import Map from './Map';
import SelectedLocationInfo from './SelectedLocationInfo';

type LocationPickerProps = {
  onLocationSelect: (location?: string | null) => void;
};

const LocationPicker: React.FC<LocationPickerProps> = ({ onLocationSelect }) => {
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);

  const handleSearch = (query: string) => {
    // Perform search based on the query, update searchResults state
    // You can use the Google Maps API or any other geocoding service here

    // Example: Mock search results
    const results: SearchResult[] = [
      { id: '1', name: 'Location A' },
      { id: '2', name: 'Location B' },
      { id: '3', name: 'Location C' },
    ];

    setSearchResults(results);
  };

  const handleResultSelect = (id: string) => {
    // Get the selected location based on the id from searchResults
    const selectedResult = searchResults.find((result) => result.id === id);

    if (selectedResult) {
      setSelectedLocation(selectedResult.name);
      onLocationSelect(selectedResult.name);
    }
  };

  const handleClearSelectedLocation = () => {
    setSelectedLocation(null);
    onLocationSelect(null);
  };

  return (
    <div>
      <SearchInput onSearch={handleSearch} />
      <SearchResultList searchResults={searchResults} onResultSelect={handleResultSelect} />
      <Map selectedLocation={selectedLocation} />
      <SelectedLocationInfo
        selectedLocation={selectedLocation}
        onClearSelectedLocation={handleClearSelectedLocation}
      />
    </div>
  );
};

export default LocationPicker;
