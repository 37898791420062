import { FC, useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Button, Container, Input } from "reactstrap";

import { HeaderPortal, PortalModalAdd, PropertyModalForm, PropertyTypeModalForm, TableAgents, TableProperties } from "components";

import { PortalView, PropertyObject } from "@inmomatch-app/shared/interface";
import { UserContext, UserContextType } from "context";
import { UserType } from "helpers/UserType";
import { UserDoc } from "interface/UserDoc";

import PageLoader from "./PageLoader";

import '@inmomatch-app/shared/css/style.css';
import 'styles/Portal.css';

const PortalViewDict = {
    inv: "Propiedades",
    agents: "Agentes inmobiliarios",
    admins:"Administradores"   
}

const PortalPage:FC<PortalView> = ({portalView}) => {
    const { getAllAgents, getAllProperties, getMyProperties, removeProperty, userDoc } = useContext(UserContext) as UserContextType;
    
    const [agents, setAgents] = useState<UserDoc[]>([]);
    const [properties, setProperties] = useState<PropertyObject[]>([]);
    
    const [currentProp, setCurrentProp] = useState<PropertyObject | null>(null);
    const [propertyType, setPropertyType] = useState<string>('')

    const [modalPropertyForm, setModalPropertyForm] = useState<boolean>(false);
    const [modalTypeSelect, setModalTypeSelect] = useState<boolean>(false);
    const [modalUserForm, setModalUserForm] = useState<boolean>(false);
    
    const [loading, setLoading] = useState<boolean>(true);
    
    const [editing, setEditing] = useState<boolean>(false);

    const toggleTypeSelect = () => {
        setCurrentProp(null);
        setEditing(false);
        setModalTypeSelect(!modalTypeSelect);
    }

    const toggleModalPropertyForm = () => setModalPropertyForm(!modalPropertyForm);
    const toggleModalUserForm = () => setModalUserForm(!modalUserForm);

    const setType = (newType: string) => {
        setPropertyType(newType);
        toggleModalPropertyForm();
    }

    const handleEdit = (property: PropertyObject) => {
        setCurrentProp(property);
        setEditing(true);
        setPropertyType(property.propertyType);
        toggleModalPropertyForm();
    }

    const handleDelete = async (property: any) => {
        setLoading(true);
        const res = await removeProperty(property);
        if(res) {
            let newProperties = properties?.filter(p => {
                return p._id !== property._id;
            });
            setProperties(newProperties);
        }
        setLoading(false);
    }

    const onButtonAddClick = () => {
        switch(portalView) {
            case "inv":
                toggleTypeSelect();
                break;
            case "agents":
                toggleModalUserForm();
                break;
            case "admins":
                toggleModalUserForm();
                break;        
        }
    }

    const fetchAgents = async () => {
        if(!userDoc) return;
        if(userDoc.userType === UserType.superadmin) {
            getAllAgents().then(res => setAgents(res));
        }
    }

    const fetchProperties = async () => {
        if(!userDoc) return;
        if(userDoc.userType === UserType.agent) {
            getMyProperties()
                .then(res => setProperties(res));
        }else { 
            getAllProperties().then(res => setProperties(res));
        }
    }

    useEffect(() => {
        if(!userDoc) return;
        switch(portalView) {
            case "inv":
                fetchProperties().then(() => setLoading(false));
                break;     
            case "admins":
                setLoading(false);
                break;
            case "agents":
                fetchAgents().then(() => setLoading(false));
                break;
        }
    // eslint-disable-next-line    
    }, [portalView])

    if(!userDoc) return <Navigate to='/' />    

    if(loading) return <PageLoader />

    return (
        <Container fluid className='p-0 m-0' id='inv-page'>
            <HeaderPortal />
            <Container fluid
                className='px-3 pt-4'
            >
                <span className='text-dark px-2 fs-1 fw-bold prevent-select'>
                    {PortalViewDict[portalView]}
                </span>
                <Container fluid
                    className='px-5 pt-3'
                >   
                    <Input
                        className='mt-2 mb-4 border-dark border-2 no-focus' 
                        type='search'
                        bsSize='lg'
                        placeholder='Buscar'
                        onChange={() => {}}
                    />
                    {
                        portalView === 'inv'
                        ? <TableProperties properties={properties} handleEdit={handleEdit} handleDelete={handleDelete}/>
                        : portalView === 'agents'
                        ? <TableAgents agents={agents} handleEdit={() => {}} handleDelete={() => {}} />
                        : <TableAgents agents={[]} handleEdit={() => {}} handleDelete={() => {}} />
                    }
                </Container>
                <Container fluid
                        className='mt-3 px-5 pb-5 prevent-select 
                        d-flex justify-content-end align-items-center'
                >
                    <Button id='button-add' onClick={onButtonAddClick}
                        className='text-light fw-bold border-0 no-focus'
                    >
                        Agregar
                    </Button>
                </Container>
            </Container>
            <PropertyTypeModalForm isOpen={modalTypeSelect} toggle={toggleTypeSelect} setType={setType} />
            <PropertyModalForm 
                isOpen={modalPropertyForm}
                toggle={toggleModalPropertyForm}
                propertyType={propertyType}
                editing={editing}
                property={currentProp}
            />
            <PortalModalAdd
                portalView={portalView}
                isOpen={modalUserForm}
                toggle={toggleModalUserForm}
            />
        </Container>
    )
}

export default PortalPage;