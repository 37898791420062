import { createContext, FC, ReactNode, useState } from "react";
import { AuthProvider, useFirebaseApp } from "reactfire";

import { 
    getAuth,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    User
} from 'firebase/auth';

import axios, {AxiosRequestConfig} from "axios";

import { PropertiesSearchQuery, PropertyObject, PropertyType } from "@inmomatch-app/shared/interface";
import { UserDoc } from "interface/UserDoc";
import { useSessionStorage } from "usehooks-ts";

export interface UserContextType {
    logInEmailPasswd: (email: string, password: string) => Promise<boolean>;
    logout: () => Promise<void>;
    resetPasswd: (email: string) => Promise<void>;
    uploadProperty: (property: FormData) => Promise<boolean>;
    editProperty: (property: FormData) => Promise<boolean>;
    getAllProperties: () => Promise<PropertyObject[]>;
    getMyProperties: () => Promise<PropertyObject[]>;
    getProperty: (propertyType: PropertyType, propertyId: string) => Promise<any>;
    removeProperty: (property: any) => Promise<boolean>;
    searchProperties: (query: PropertiesSearchQuery) => Promise<any>;
    getAllAgents: () => Promise<UserDoc[]>;
    getUserDoc: (uid: string) => Promise<UserDoc | null>;
    editUserDoc: (userDoc: UserDoc) => Promise<boolean>;
    user: User | null;
    userDoc: UserDoc | null;
}

export const UserContext = createContext<UserContextType | null>(null);

export const UserProvider: FC<{children: ReactNode}> = ({children}) => {
    const app = useFirebaseApp();
    const auth = getAuth(app);
    const [user, setUser] = useState<User | null>(null);
    const [userDoc, setUserDoc] = useSessionStorage<UserDoc | null>('userDoc',null);

    onAuthStateChanged(auth, (user) => {
        setUser(user);
        if(!user) return setUserDoc(null);
        if(!userDoc) getUserDoc(user.uid).then(res => setUserDoc(res));
        if(userDoc && userDoc.uid !== user.uid) getUserDoc(user.uid).then(res => setUserDoc(res));
    })

    const getAllAgents = async (): Promise<UserDoc[]> => {
        const apiURL = process.env.REACT_APP_API_URL;
        const config: AxiosRequestConfig = {
            url: `${apiURL}/get-all-agents`,
            method: 'GET',
        };
        const agents = await axios(config)
            .then(res => res.data)
            .catch(err => {console.error(err); return []})
        return agents;
    }

    const getUserDoc = async (uid: string): Promise<UserDoc | null> => {
        const apiURL = process.env.REACT_APP_API_URL;
        const params = {
            uid: uid
        }
        const config: AxiosRequestConfig = {
            url: `${apiURL}/get-user-doc`,
            method: 'GET',
            params: params
        }
        
        const userDoc = await axios(config)
            .then(res => res.data as UserDoc)
            .catch(_ => null)

        return userDoc;
    }

    const editUserDoc = async (userDoc: UserDoc): Promise<boolean> => {
        const apiURL = process.env.REACT_APP_API_URL;
        const config: AxiosRequestConfig = {
            url: `${apiURL}/edit-user-doc`,
            method: 'POST',
            data: userDoc
        }

        const result = await axios(config)
            .then(res => res.status === 200)
            .catch(err => {console.error(err); return false});

        if(result) setUserDoc(userDoc);

        return result;
    }

    const logInEmailPasswd = async (email: string, password: string):Promise<boolean> => {
        try {
            const user = await signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    return userCredential.user;
                });
            auth.updateCurrentUser(user);
            return true;
        } catch (err: any) {
            console.log(err);
            return false;
        }
    };

    const logout = async () => {
        await signOut(auth).then(() => setUserDoc(null));
    };

    const resetPasswd = async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email);
            alert('Password reset link sent');
        } catch(err: any) {
            console.error(err);
            alert(err?.message);
        }
    };

    const removeProperty = async (property: any): Promise<boolean> => {
        const apiURL = process.env.REACT_APP_API_URL;
        const data = {
            propertyType: property.propertyType,
            propertyId: property._id,
            images: property.images
        }
        const config: AxiosRequestConfig = {
            url: `${apiURL}/remove-property`,
            method: 'POST',
            data: data
        }

        const res = await axios(config)
            .then(res => {return res.status===200})
            .catch(err => {console.log(err); return false})
        
        return res;    
    }

    const uploadProperty = async (property: FormData): Promise<boolean>  => {
        const apiURL = process.env.REACT_APP_API_URL;
        const uid = user?.uid;
        if(!uid) throw new Error('firebase uid is not defined');
        property.append('identifier', uid);
        const config: AxiosRequestConfig = {
            url: `${apiURL}/add-property`,
            method: 'POST',
            data: property,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const res = await axios(config)
            .then(res => {return res.status===200})
            .catch(err => {console.log(err); return false})

        return res;
    }

    const editProperty = async (property: FormData): Promise<boolean> => {
        const apiURL = process.env.REACT_APP_API_URL;
        const uid = user?.uid;
        if(!uid) throw new Error('firebase uid is not defined');
        property.append('identifier', uid);
        const config: AxiosRequestConfig = {
            url: `${apiURL}/edit-property`,
            method: 'POST',
            data: property,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const res = await axios(config)
            .then(res => {return res.status===200})
            .catch(err => {console.log(err); return false})

        return res;
    }

    const getMyProperties = async (): Promise<PropertyObject[]> => {
        const apiURL = process.env.REACT_APP_API_URL;
        const uid = userDoc?.uid;
        const config: AxiosRequestConfig = {
            url: `${apiURL}/get-my-properties`,
            method: 'GET',
            params: { ownerID: uid }
        };

        const res = await axios(config)
            .then(res => {
                return res.data;
            })
            .catch(err => {
                console.log(err);
                return null;
            })
        return res as any[];
    }

    const getAllProperties = async (): Promise<PropertyObject[]> => {
        const apiURL = process.env.REACT_APP_API_URL;
        const config: AxiosRequestConfig = {
            url: `${apiURL}/get-all-properties`,
            method: 'GET',
        };
        const properties = await axios(config)
            .then(res => res.data)
            .catch(err => {console.error(err); return []})
        return properties;    
    }

    const getProperty = async(propertyType: PropertyType, propertyId: string): Promise<any> => {
        const apiURL = process.env.REACT_APP_API_URL;
        const config: AxiosRequestConfig = {
            url: `${apiURL}/get-property`,
            method: 'POST',
            data: {
                propertyType: propertyType,
                propertyId: propertyId
            }
        };

        const property = await axios(config)
            .then(res => {
                return res.data;
            })
            .catch(err => console.log(err.message));
        
        return property;
    }

    const searchProperties = async (query: PropertiesSearchQuery): Promise<any> => {
        const apiURL = process.env.REACT_APP_API_URL;
        const config: AxiosRequestConfig = {
            url: `${apiURL}/search-properties`,
            method: 'POST',
            data: query
        };
        const res = await axios(config)
            .then((res) => res.data)
            .catch((err) => console.error(err));
        return res;    
    }

    const provider = {
        logInEmailPasswd,
        resetPasswd,
        logout,
        uploadProperty,
        editProperty,
        getAllProperties,
        getMyProperties,
        getProperty,
        removeProperty,
        searchProperties,
        getAllAgents,
        getUserDoc,
        editUserDoc,
        user,
        userDoc
    };

    return (
        <UserContext.Provider value={provider}>
            <AuthProvider sdk={auth}>
                {children}
            </AuthProvider>
        </UserContext.Provider>
    );
};

export default UserProvider;