import { Table } from "reactstrap";

import { UserDoc } from "interface/UserDoc";

import '@inmomatch-app/shared/css/style.css';
import 'styles/Portal.css';

type TableAgentsProps = {
    agents: UserDoc[];
    handleEdit: (agent: UserDoc) => void;
    handleDelete: (agent: UserDoc) => void;
}

const TableAgents = ({agents, handleEdit, handleDelete}: TableAgentsProps): JSX.Element => {
    return (
        <Table
            hover
            striped
        >
            <thead>
                <tr>
                    <th>
                        Nombre   
                    </th>
                    <th>
                        Apellidos
                    </th>
                    <th>
                        Contacto
                    </th>
                    <th>
                        Administrador
                    </th>
                    <th>
                        Estado
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    agents.map((agent, index) => (
                        <tr key={index}>
                            <td>
                                {agent.name}
                            </td>
                            <td>
                                {agent.lastName}
                            </td>
                            <td>
                                {agent.phone}
                            </td>
                            <td>
                                {'admin'}
                            </td>
                            <td>
                                Estado
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    )
}

export default TableAgents;