import React from 'react';

type SelectedLocationInfoProps = {
  selectedLocation: string | null;
  onClearSelectedLocation: () => void;
};

const SelectedLocationInfo: React.FC<SelectedLocationInfoProps> = ({
  selectedLocation,
  onClearSelectedLocation,
}) => {
  return (
    <div>
      {selectedLocation ? (
        <>
          <p>Selected Location: {selectedLocation}</p>
          <button onClick={onClearSelectedLocation}>Clear Location</button>
        </>
      ) : (
        <p>No location selected.</p>
      )}
    </div>
  );
};

export default SelectedLocationInfo;
