import PropertyForm from "components/PropertyForm/PropertyForm"


const PageForm = () => {
  return (
    <div className="w-75 mx-auto">
      <PropertyForm />
    </div>
  )
}

export default PageForm;