import React, { useEffect, useRef } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

type MapProps = {
  selectedLocation: string | null;
};

const Map: React.FC<MapProps> = ({ selectedLocation }) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const map = useRef<google.maps.Map | undefined>(undefined);

  useEffect(() => {
    if (selectedLocation) {
      // Initialize map if a selected location exists
      if (! map.current && mapRef.current && google.maps) {
        map.current = new google.maps.Map(mapRef.current!, {
          center: { lat: 0, lng: 0 }, // Set initial center point
          minZoom: 15,
          maxZoom: 15,
          zoom: 15, // Set initial zoom level
          gestureHandling: "none",
          zoomControl: false,
          disableDefaultUI: true
        });

        //map.current.addListener('click', (event: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => mapClickHandler(event));
      }

      //const mapClickHandler = (event:  google.maps.MapMouseEvent| google.maps.IconMouseEvent) => {
      //  if (event.latLng) {
      //    map.current?.panTo(event.latLng);
      //    map.current?.setZoom(10);
      //  }
      //}

      // Use Geocoding service to get coordinates for the selected location
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: selectedLocation }, (results, status) => {
        if (map.current && status === 'OK' && results && results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          const position = new google.maps.LatLng(lat(), lng());

          // Set map center and marker position to the selected location
          map.current.setCenter(position);

          const markerProps = {
            position: position,
            map: map.current,
          }

          new google.maps.Marker(markerProps);
        }
      });
    }
  }, [selectedLocation]);

  return (
    <div
      ref={mapRef}
      style={{
        height: '60vh'
      }}
    />
  );
};

const MapComponent = ({selectedLocation}: MapProps) => {
  const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';
  
  const render = (status: Status) => {
      console.log(status);
      switch (status) {
        case Status.LOADING:
          return <h3>Loading...</h3>
        case Status.FAILURE:
          return <h3>{status}</h3>
        case Status.SUCCESS:
          return <Map selectedLocation={selectedLocation}/>;
      }
  };

  return(
      <Wrapper apiKey={mapsApiKey} render={render} />
  )
}

export default MapComponent;
