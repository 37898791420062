import React from 'react';

export type SearchResult = {
  id: string;
  name: string;
};

type SearchResultListProps = {
  searchResults: SearchResult[];
  onResultSelect: (id: string) => void;
};

const SearchResultList: React.FC<SearchResultListProps> = ({
  searchResults,
  onResultSelect,
}) => {
  const handleResultSelect = (id: string) => {
    onResultSelect(id);
  };

  return (
    <ul>
      {searchResults.map((result) => (
        <li key={result.id} onClick={() => handleResultSelect(result.id)}>
          {result.name}
        </li>
      ))}
    </ul>
  );
};

export default SearchResultList;
