import { useContext, useEffect, useState } from "react"
import { Navigate } from "react-router-dom";
import { useSigninCheck } from "reactfire";

import { UserContext, UserContextType } from "context";
import { PageLoader } from "pages";
import { UserType } from "helpers/UserType";

type PrivateRouterProps = {
    route: JSX.Element
    authValue: UserType;
}

enum AuthStatus {
    'loading' = 0,
    'failure' = 1,
    'success' = 2
}

const PrivateRouter = ({route, authValue}: PrivateRouterProps): JSX.Element => {
    const { getUserDoc, userDoc } = useContext(UserContext) as UserContextType;
    const { status, data: signInCheckResult } = useSigninCheck();
    const [authStatus, setAuthStatus] = useState<AuthStatus>(AuthStatus.loading);

    useEffect(() => {
        if(signInCheckResult.signedIn === false) return setAuthStatus(AuthStatus.failure);

        // "somehow" userDoc loaded
        if(userDoc) {
           return setAuthStatus( userDoc.userType <= authValue ? AuthStatus.success : AuthStatus.failure );
        }

        getUserDoc(signInCheckResult.user.uid)
            .then(udoc => {
                setAuthStatus( udoc && udoc.userType <= authValue ? AuthStatus.success : AuthStatus.failure );
            })
    // eslint-disable-next-line        
    }, [signInCheckResult])
    
    if(status === 'error' || authStatus === AuthStatus.failure) return <Navigate to='/' /> 

    if(authStatus === AuthStatus.success) return route;

    return <PageLoader />
}

export default PrivateRouter;