import { FC } from "react";
import { Container } from "reactstrap";

import '@inmomatch-app/shared/css/style.css';
import 'styles/Property.css';

interface PropertyCardListProps {
    properties: Array<JSX.Element>
}

const PropertyCardList:FC<PropertyCardListProps> = ({properties}) => {
    return (
        <Container fluid className='d-flex p-0 pb-1' id='property-list'>
            {properties}
        </Container>
    )
}

export default PropertyCardList;