import { FC } from "react";
import { Container } from "reactstrap";
import ProfileComponent from "./ProfileComponent";

import '@inmomatch-app/shared/css/style.css';
import 'styles/Header.css';

const HeaderPortal: FC = () => {
    return (
        <Container fluid id='header-portal'
            className='px-3 fw-bold text-light bg-primary prevent-select
            d-inline-flex justify-content-between align-items-center'
        >
            <Container className='m-0 p-0 d-flex align-items-center fit-content'>
                <ProfileComponent logged={true} />
                <span>
                    Nombre de la persona
                </span>
            </Container>
            <Container className='m-0 p-0 fit-content'>
                <span>
                    Portal de asesores inmobiliarios
                </span>
            </Container>
        </Container>
    )
}

export default HeaderPortal;