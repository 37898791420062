import { FC } from "react";
import { Button, Card, CardBody, CardHeader, List } from 'reactstrap';

import { PricingTableCardProps } from '@inmomatch-app/shared/interface';

import '@inmomatch-app/shared/css/style.css';
import 'styles/Pricing.css';

const PricingTableCard:FC<PricingTableCardProps> = ({name, price, properties, perks}) => {
    return(
        <Card
            id='price-card'
            className='mb-4 rounded-3 shadow-sm'
        >
            <CardHeader id='header'>
                <span 
                    className='fw-bold fs-2'
                >
                    {name}
                </span>
            </CardHeader>
            <CardBody>
                <h1 className='text-primary'>
                    ${price}
                    <span
                        className='text-muted fw-light fs-5'
                    >
                        mxn
                    </span>
                    <small
                        className='text-muted fw-light fs-5'
                    >
                        /mes
                    </small>
                </h1>
                <List className='text-start'>
                    {perks.map((perk: string, index: number) => {
                        return(
                            <li key={index}>
                                {perk}
                            </li>
                        )
                    })}
                    <li>
                        {properties === Number.MAX_SAFE_INTEGER ? 
                            <span>Propiedades <strong>ilimitadas</strong></span>
                            :
                            <span>{properties} propiedades</span>
                        }
                    </li>
                </List>
            </CardBody>
            <Button color='primary' id='button'
                className='mx-auto mb-3 text-light w-50 fw-bold fit-content'
                disabled={name==='Prueba'}
            >
                {name==='Prueba' ? 
                    'Activo'
                    :
                    'Continuar'
                }
            </Button>
        </Card>
    )
}

export default PricingTableCard;