import { Routes, Route, Navigate, useLocation, BrowserRouter } from 'react-router-dom';
import { EditProfile, Home, PortalPage, Login, PropertyPage, PricingPage, SearchResultsPage } from 'pages';
import PageForm from 'pages/PageForm';
import { PrivateRouter } from './conditional';
import { UserType } from 'helpers/UserType';

export const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/property/:propertyType/:id" element={<PropertyPage />} />
                <Route path='/form' element={<PageForm />} />
                <Route path='/edit-profile' element={ <PrivateRouter route={<EditProfile />} authValue={UserType.agent} />} />
                <Route path='/inventory' element={ <PrivateRouter route={<PortalPage portalView='inv' />} authValue={UserType.agent} /> } />
                <Route path='/agents' element={ <PrivateRouter route={<PortalPage portalView='agents'/>} authValue={UserType.admin} /> } />
                <Route path='/admins' element={ <PrivateRouter route={<PortalPage portalView='admins'/>} authValue={UserType.superadmin}/> } />
                <Route path='/pricing' element={<PricingPage  />} />
                <Route path='/search' element={<SearchRouter /> } />
                <Route path="/*" element={<Navigate to="/home" />} />
            </Routes>
        </BrowserRouter>
    )
}

const SearchRouter = () => {
    const { state } = useLocation();

    if(!state) return <Navigate to="/home" />

    return <SearchResultsPage properties={state} />
}