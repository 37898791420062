import { useContext } from 'react';
import { Container } from 'reactstrap';

import { Logo } from 'assets';
import { UserContext, UserContextType } from 'context';
import Searchbar from './Searchbar';
import ProfileComponent from './ProfileComponent';

import '@inmomatch-app/shared/css/style.css';
import 'styles/Header.css';

const Header = () => {
    const { user } = useContext(UserContext) as UserContextType
    return (
        <Container fluid id='header'
            className='p-0 px-2 bg-primary d-flex justify-content-between align-items-center'
        >
            <img className='logo prevent-select' alt='logo' draggable={false} src={Logo}/>
            <Searchbar />
            <ProfileComponent logged={user !== null} />
        </Container>
    )
}

export default Header;