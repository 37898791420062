import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Row, Spinner } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock ,faUser } from "@fortawesome/free-solid-svg-icons";

import { UserContext, UserContextType } from "context";
import { Logo } from "assets";

const LoginForm:FC = () => {
    const { logInEmailPasswd } = useContext(UserContext) as UserContextType;
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [invalid, setInvalid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const loginFunc = async (email:string, password: string) => {
        setLoading(true);
        const logged = await logInEmailPasswd(email, password);
        if(logged) {
            navigate('/home');
        }
        else {
            setInvalid(true);
            setLoading(false);
        }
    }

    return (
        <Container className='sm d-flex flex-column login-form h-75 align-items-center justify-content-center'>
            <Container className='mb-4 prevent-select'>
                <Row className="text-center align-items-center">
                    <Col className="1" >
                        <img src={Logo} className='logo' alt='logo' draggable={false}/>
                    </Col>
                    <Col className="10">
                        <span className="inmomatch">Inmomatch</span>
                    </Col>
                    <Col className="1"></Col>
                </Row>
                <Row className="text-center mt-2">
                    <span>Haz match con el inmueble ideal a tus peticiones</span>
                </Row>
            </Container>
            <Form onSubmit={(ev) => {ev.preventDefault(); loginFunc(email, password);}}>
                <FormGroup>
                    <InputGroup>
                        <InputGroupText>
                            <FontAwesomeIcon icon={faUser} />
                        </InputGroupText>
                        <Input
                            className='no-focus'
                            id='email'
                            name='email'
                            placeholder="Correo"
                            type='email'
                            bsSize='lg'
                            invalid={invalid}
                            onChange={ev => {setEmail(ev.target.value)}}
                        />
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <InputGroup>
                        <InputGroupText className='prevent-select'>
                            <FontAwesomeIcon icon={faLock} />
                        </InputGroupText>
                        <Input
                            className='no-focus'
                            id='passwd'
                            name='passwd'
                            placeholder="Contraseña"
                            type='password'
                            bsSize='lg'
                            invalid={invalid}
                            onChange={ev => setPassword(ev.target.value)}
                        />
                    </InputGroup>
                </FormGroup>
                { invalid ?
                    <span className='text-danger p-0 px-1 prevent-select'>Error: correo o contraseña incorrectos</span>    
                    :
                    null
                }
                <Row className='prevent-select'>
                    <span className='link'>¿Olvidaste tu contraseña?</span>
                </Row>
                <Container className='mt-5 p-0 d-flex fluid align-items-center justify-content-end prevent-select'>
                    <a href='home' style={{all: 'unset'}}>
                        <span className='text-primary m-2 link'> Regresar</span>
                    </a>
                    <Button 
                        className="primary no-focus login-button"
                        type="submit"
                    >
                        {
                            loading ?
                            <span><Spinner color='light' size='sm' />Cargando</span>
                            :
                            <span>Ingresar</span>
                        }
                    </Button>
                </Container>
            </Form>
        </Container>
    )
}

export default LoginForm;