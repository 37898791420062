import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row, UncontrolledCarousel } from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBath,
    faBed,
    faChartArea,
    faClock,
    faHouse,
    faLandmark,
    faMoneyBillWave,
    faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { UserContext, UserContextType } from "context";

import { PropertyObject, PropertyType } from "@inmomatch-app/shared/interface";
import { Header, PropertyCardList, PropertiesDefault, Footer } from "components";
import Map from "components/LocationPicker/Map";
import { HouseDefaultFull } from "assets";

import PageLoader from "./PageLoader";

import '@inmomatch-app/shared/css/style.css';
import 'styles/Property.css';

type PropertyParams = {
    propertyType: PropertyType;
    id: string;
}

const defaultProperty: PropertyObject = {
    _id: 'id',
    propertyType: 'Casa',
    adquisitionType: 'Compra',
    images: [HouseDefaultFull],
    price: 15000000,
    location: '1600 Amphitheatre Parkway Mountainview California',
    description: 'Dolorum nihil vitae praesentium. Molestiae sit et recusandae dolorem. Nobis voluptatem placeat porro a et. Ea illo et voluptatibus inventore similique. Sed natus nisi rerum.',
    ownerID: 'test',
    bathrooms: 3.5,
    bedrooms: 7,
    parkingSpots: 3,
    age: '<5 años',
    lotArea: 200,
    buildingArea: 150   
}

const capitalizeFLetter = (str: string): string => {
    return str[0].toUpperCase() + str.slice(1);
}

const PropertyPage = () => {
    const { getProperty } = useContext(UserContext) as UserContextType;
    const { propertyType, id } = useParams() as PropertyParams;
    const [loading, setLoading] = useState<boolean>(true);
    const [property, setProperty] = useState<PropertyObject>(defaultProperty);

    const navigate = useNavigate();

    useEffect(() => {
        getProperty(capitalizeFLetter(propertyType) as PropertyType, id)
            .then(res => {
                if(res.errors) navigate('/');
                setProperty(res);
                console.log(res);
                setLoading(false);
            })
            .catch(err => console.log(err));
    }, [propertyType, getProperty, id, navigate]);

    const getName = ():string => {
        return property.location.split(',')[1];
    }

    if(loading) return <PageLoader />

    return (
        <Container fluid className='p-0 property-page'>
            <Header />
            <Container fluid className='p-5 pb-0 flex-sm-row'>
                <span className='fs-1 fw-bold'>{getName()}</span>
                <Row>
                    <Col className='7'>
                        <UncontrolledCarousel
                            items={
                                property.images.map((imageSrc, index) => (
                                    {
                                        src: imageSrc,
                                        key: index
                                    }
                                ))
                            }
                            controls={false}
                        />
                    </Col>
                    <Col className='5'>
                        <Container className='d-flex flex-column'>
                            <span className='fs-2 fw-bold'>{Number(property.price).toLocaleString()}$ MXN</span>
                            <span className='fs-5'>
                                {property.description}
                            </span>
                            <span className='fs-3 fw-bold mt-2'>Características</span>
                            <PropertyAttributes
                                property={property ?? defaultProperty}
                            />
                            <Container fluid className='d-flex p-0 mt-1' id='match-btn'>
                                <Button className='no-focus fw-bold'>
                                    <FontAwesomeIcon icon={faWhatsapp} id='whatsapp' />
                                    <span>Haz Match!</span>
                                </Button>
                            </Container>
                        </Container>
                    </Col>
                </Row>
                <Container fluid className='mt-4 p-0'>
                    <span className='fs-2 fw-bold'>Ubicación en el mapa</span>
                    <Map selectedLocation={property.location}/>
                </Container>
                <span className='fs-2 fw-bold'>Propiedades relacionadas</span>
                <Container fluid className='mt-4 p-0'>
                    <PropertyCardList properties={PropertiesDefault} />
                </Container>
            </Container>
            <Footer />
        </Container>
    )
}

interface PropertyAttributesProps {
    property: PropertyObject;
}

const PropertyAttributes = ({property}: PropertyAttributesProps): JSX.Element => {
    return (
        <Container fluid className='mt-2 fs-5 fw-bold attrs'>
            <Row className='mb-3'>
                <Col md={6} className='p-0 mb-2' id='which'>
                    <FontAwesomeIcon icon={faMoneyBillWave} className='text-primary me-2'/>
                    <span>{property.adquisitionType}</span>
                </Col>
                {
                    property.parkingSpots ?
                    <Col md={6} className='p-0 mb-2' id='parking'>
                        <FontAwesomeIcon icon={faWarehouse} className='text-primary me-2'/>
                        <span>{property.parkingSpots} espacios para carros</span>
                    </Col>
                    :
                    null
                }
                {
                    property.bathrooms?
                    <Col md={6} className='p-0 mb-2' id='baths'>
                        <FontAwesomeIcon icon={faBath} className='text-primary me-2'/>
                        <span>{property.bathrooms} baños</span>
                    </Col>
                    :
                    null
                }
                {
                    property.age?
                    <Col md={6} className='p-0 mb-2' id='age'>
                        <FontAwesomeIcon icon={faClock} className='text-primary me-2'/>
                        <span>{property.age} antigüedad</span>
                    </Col>
                    :
                    null
                }
                {   
                    property.bedrooms ?
                    <Col md={6} className='p-0 mb-2' id='bedrooms'>
                        <FontAwesomeIcon icon={faBed} className='text-primary me-2'/>
                        <span>{property.bedrooms} habitaciones</span>
                    </Col>
                    :
                    null
                }
                {
                    property.lotArea ?
                    <Col md={6} className='p-0 mb-2' id='area-terrain'>
                        <FontAwesomeIcon icon={faChartArea} className='text-primary me-2'/>
                        <span>{property.lotArea}m<sup>2</sup> terreno</span>
                    </Col>
                    :
                    null
                }
                <Col md={6} className='p-0 mb-2' id='type'>
                    <FontAwesomeIcon icon={faHouse} className='text-primary me-2'/>
                    <span>{property.propertyType}</span>
                </Col>
                {
                    property.buildingArea ? 
                    <Col md={6} className='p-0 mb-2' id='area-built'>
                        <FontAwesomeIcon icon={faLandmark} className='text-primary me-2'/>
                        <span>{property.buildingArea}m<sup>2</sup> construcción</span>
                    </Col>
                    :
                    null
                }
            </Row>
        </Container>
    )
}

export default PropertyPage;