import { FC, useState } from "react";

import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";

import '@inmomatch-app/shared/css/style.css';
import 'styles/Carousel.css';

interface CarouselComponent {
    imageList: Array<{src: string}>;
}

const ImageCarousel: FC<CarouselComponent> = ({ imageList }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const previousSlide = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? imageList.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const nextSlide = () => {
        if (animating) return;
        const nextIndex = activeIndex === imageList.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex: number) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = imageList.map((item) => {
        return (
            <CarouselItem 
            className="text-center prevent-select"
            onExiting={() => setAnimating(true)} 
            onExited={() => setAnimating(false)} 
            key={item.src}>
                <img src={item.src} alt="placeholder" draggable={false} className="img-fluid" />
            </CarouselItem>
        );
    });

    return (
        <div className="image-carousel">
            <Carousel activeIndex={activeIndex} next={nextSlide} previous={previousSlide}>
                <CarouselIndicators 
                    items={imageList}
                    activeIndex={activeIndex} 
                    onClickHandler={goToIndex}
                />
                {slides}
            </Carousel>
        </div>
    );
}

export default ImageCarousel;