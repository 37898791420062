import { Container } from "reactstrap";

import { Logo } from "assets";

import '@inmomatch-app/shared/css/style.css';
import 'styles/PageLoader.css';

const PageLoader = () => {
    return (
        <Container
            fluid
            className='d-flex flex-column align-items-center justify-content-center bg-primary m-0 p-0'
            id='page-loader'
        >
            <img 
                alt='inmomatch logo'
                src={Logo}
                className='img-fluid mb-5 prevent-select'
                id='logo'
                draggable={false}
            />
        </Container>
    )
}

export default PageLoader;