import { FormEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import { Button, ButtonGroup, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap';

import { AdquisitionType, PropertiesSearchQuery, PropertyType } from '@inmomatch-app/shared/interface';
import { UserContext, UserContextType } from 'context';

import '@inmomatch-app/shared/css/style.css';
import 'styles/Header.css';

const Searchbar = (): JSX.Element => {
    const { searchProperties } = useContext(UserContext) as UserContextType;
    const [modal, setModal] = useState(false);
    const [adquisitionType, setAdquisitionType] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const toggle = () => setModal(!modal);
    const navigate = useNavigate();

    const submitQuery = (query: PropertiesSearchQuery) => {
        setLoading(true);
        console.log(query);
        searchProperties(query)
            .then((res) => {
                console.log(res)
                setLoading(false);
                navigate('/search', {
                    state: res
                })
            });
    }

    const updateAdquisitionType = (newType: string) => {
        setAdquisitionType(newType);
    }

    return (
        <Container className='p-0 fit-content'>
            <Container onClick={toggle} id='searchbar'
                className='d-flex align-items-center'
            >
                <InputGroup>
                    <Container id='text-input'
                        className='rounded-3 d-flex align-items-center'
                    >
                        <span id='placeholder' className='fs-4'>Buscar</span>
                    </Container>
                    <InputGroupText id='search-icon'
                        className='rounded-3 fs-3'
                    >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </InputGroupText>
                </InputGroup>
            </Container>
            <Modal id='modal-search' className='m-0 p-0' size='xl' 
                unmountOnClose={false} isOpen={modal} toggle={toggle}
            >
                <ModalBody className='bg-primary border-0'>
                    <Container fluid className='p-0 m-0 d-flex align-items-center justify-content-between'>
                        <Container fluid className='p-0'>
                            <Container className='mb-1 text-center'>
                                <RadioButtons
                                    updateAdquisitionType={updateAdquisitionType}
                                />
                            </Container>
                            <Container fluid
                                className='bg-light rounded-pill px-2'
                                style={{
                                    width: '85%'
                                }}
                            >
                                <SearchForm
                                    adquisitionType={adquisitionType}
                                    submitQuery={submitQuery}
                                    loading={loading}
                                />
                            </Container>
                        </Container>
                    </Container>
                </ModalBody>
            </Modal>
        </Container>
    )
}

type SearchFormProps = {
    adquisitionType: string;
    submitQuery: (query: PropertiesSearchQuery) => void;
    loading: boolean;
}

const SearchForm = ({adquisitionType, submitQuery, loading}: SearchFormProps): JSX.Element => {
    const [colQuery, setcolQuery] = useState<string>('');
    const [propertyType, setPropertyType] = useState<string>('Tipo');
    const [bedroomsQuery, setBedroomsQuery] = useState<string>('');
    const [fromQuery, setFromQuery] = useState<string>('');
    const [toQuery, setToQuery] = useState<string>('');
    const [validForm, setValidForm] = useState<boolean>(false);
    
    useEffect(() => {
        const isValid = propertyType !== 'Tipo' && adquisitionType.length > 0;
        setValidForm(isValid);
    }, [propertyType, adquisitionType])

    const onSubmit = (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        const numBedrooms = propertyType === 'Casa' || propertyType === 'Departamento' ?
            Number(bedroomsQuery.replace(/\D+/g, ''))
            :
            null
        
        const searchQuery: PropertiesSearchQuery = {
            neighborhood: colQuery,
            propertyType: propertyType as PropertyType,
            minPrice: Number(fromQuery.replace(/\D+/g,'')),
            maxPrice: Number(toQuery.replace(/\D+/g, '')) || Number.MAX_SAFE_INTEGER,
            adquisitionType: adquisitionType as AdquisitionType,
            bedrooms: numBedrooms
        }
        submitQuery(searchQuery);
    }

    return(
        <Form onSubmit={onSubmit}>
            <Row className='px-4'>
                <Col md='4' className='ps-5 rounded-pill'>
                    <FormGroup>
                        <Label for='text-col'
                            className='m-0 p-0 fw-bold fs-4 user-select-none'
                        >
                            Colonia
                        </Label>
                        <Input
                            bsSize='lg'
                            id='text-col'
                            name='col'
                            placeholder='Colonia'
                            type='text'
                            className='m-0 p-0 ps-1 border-0 no-focus search-input'
                            value={colQuery}
                            onChange={e => {setcolQuery(e.target.value)}}
                        />
                    </FormGroup>
                </Col>
                <Col md='2'>
                    <FormGroup>
                        <Label for='select-type'
                            className='fw-bold fs-4 user-select-none'
                        >
                            Tipo
                        </Label>
                        <Input
                            id='select-type'
                            name='type'
                            type='select'
                            className='no-focus border-0'
                            onChange={(ev) => setPropertyType(ev.target.value)}
                        >
                            <option>
                                Tipo
                            </option>
                            <option>
                                Casa
                            </option>
                            <option>
                                Departamento
                            </option>
                            <option>
                                Oficina
                            </option>
                            <option>
                                Local Comercial
                            </option>
                            <option>
                                Terreno
                            </option>
                            <option>
                                Bodega
                            </option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md='2'>
                {
                    propertyType === 'Casa' || propertyType === 'Departamento' ?
                    <FormGroup>
                        <Label for='select-rooms'
                            className='fw-bold fs-4 user-select-none'
                        >
                            Habitaciones
                        </Label>
                        <Input
                            id='select-rooms'
                            name='rooms'
                            type='select'
                            className='no-focus border-0'
                            onChange={(ev) => setBedroomsQuery(ev.target.value)}
                        >
                            <option>
                                Cualquiera
                            </option>
                            <option>
                                1+
                            </option>
                            <option>
                                2+
                            </option>
                            <option>
                                3+
                            </option>
                            <option>
                                4+
                            </option>
                            <option>
                                5+
                            </option>
                            <option>
                                6+
                            </option>
                        </Input>
                    </FormGroup>
                    :
                    null
                }
                </Col>
                <Col md='4'>
                    <Label for='range'
                        className='fw-bold fs-4 user-select-none'
                    >
                        Rango de precio
                    </Label>
                    <Button color='primary'
                        className='text-light ms-5 p-0 px-3'
                        id='button-search'
                        type='submit'
                        disabled={!validForm}
                    >
                        {loading ? <Spinner size='sm'/> : 'Buscar'}
                    </Button>
                    <Row id='range'>
                        <Col className='p-0 m-0'>
                            <FormGroup row>
                                <Label for='from' sm={2}
                                    className='user-select-none'
                                >
                                    De:
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        id='from'
                                        name='from'
                                        type='text'
                                        className='no-focus border-primary border-2'
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if(value.length < 12) {
                                                const numberValue = value.replace(/[^0-9]/g, '');
                                                const formattedValue = Number(numberValue).toLocaleString();
                                                setFromQuery(formattedValue);
                                            }
                                        }}
                                        value={fromQuery}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col className='p-0 m-0'>
                            <FormGroup row>
                                <Label for='to' sm={2}
                                    className='user-select-none'
                                >
                                    a:
                                </Label>
                                <Col sm={8} className='p-0 m-0'>
                                    <Input
                                        id='to'
                                        name='to'
                                        type='text'
                                        className='no-focus border-primary border-2'
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if(value.length < 12){
                                                const numberValue = value.replace(/[^0-9]/g, '');
                                                const formattedValue = Number(numberValue).toLocaleString();
                                                setToQuery(formattedValue);
                                            }
                                        }}
                                        value={toQuery}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

type RadioButtonsProps = {
    updateAdquisitionType: (adquisitionType: string) => void;
}

const RadioButtons = ({updateAdquisitionType}:RadioButtonsProps) => {
    const [selected, setSelected] = useState<number>(0);

    return (
        <ButtonGroup
            className='p-0 m-0 gap-5'
            role='group'
            aria-label='tipo de busqueda (compra, venta, remate)'
            id='search-button-group'
        >
            <Button
                color='primary'
                className='border-0 text-light no-focus fs-5'
                onClick={() => {setSelected(1); updateAdquisitionType('Compra')}}
                active={selected===1}
            >
                Compra
            </Button>
            <Button
                color='primary'
                className='border-0 text-light no-focus fs-5'
                onClick={() => {setSelected(2); updateAdquisitionType('Venta')}}
                active={selected===2}
            >
                Renta
            </Button>
            <Button
                color='primary'
                className='border-0 text-light no-focus fs-5'
                onClick={() => {setSelected(3); updateAdquisitionType('Remate')}}
                active={selected===3}
            >
                Remate
            </Button>
        </ButtonGroup>
    )    
}

export default Searchbar;