import { Container } from "reactstrap";
import { AppStoreBadge, GooglePlayBadge } from "assets";

import '@inmomatch-app/shared/css/style.css';
import 'styles/Footer.css';

const Footer = () => {
    return (
        <Container fluid id='footer'
            className='px-5 pb-2 d-flex justify-content-between prevent-select'
        >
            <Container fluid className='p-0 pt-2 d-flex flex-column' id='footer-text'>
                <span className='fs-1 fw-bold'>Descarga la app</span>
                <span className='fs-5'>
                    Molestias sed sit sed perferendis voluptas omnis. In officiis rerum officiis totam voluptatem pariatur suscipit. Labore animi non vero corporis non sint inventore. Et explicabo aut perspiciatis ducimus dolores porro. Dolorum molestiae rerum numquam quasi est nisi aliquid neque.
                </span>
            </Container>
            <Container className='p-0 d-flex flex-column justify-content-center' id='badges'>
                <img src={AppStoreBadge} draggable={false} alt='download on app store' width={120}/>
                <img src={GooglePlayBadge} draggable={false} alt='download on play store' width={120}/>
            </Container>
        </Container>
    )
}

export default Footer;