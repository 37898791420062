import React, { useState } from 'react';
import PropertyInputRow from './PropertyInputRow';
import SelectInputRow from './SelectInputRow';
//import TransactionTypeInputRow from './TransactionTypeInputRow';
import ImageInput from './ImageInput';
//import DescriptionInput from './DescriptionInput';
//import useGoogleMapsEmbed from './useGoogleMapsEmbed';
import LocationPicker from 'components/LocationPicker/LocationPicker';

type FormData = {
  propertyTitle: string;
  propertyType: string;
  transactionType: string;
  price: number;
  bedrooms: number;
  parkingSpots: number;
  antiquity: string;
  lotSize: number;
  buildingSize: number;
  images: File[];
  description: string;
  location: string;
};

const initialFormData: FormData = {
  propertyTitle: '',
  propertyType: '',
  transactionType: '',
  price: 0,
  bedrooms: 0,
  parkingSpots: 0,
  antiquity: '',
  lotSize: 0,
  buildingSize: 0,
  images: [],
  description: '',
  location: '',
};

const PropertyForm = () => {
  const [formData, setFormData] = useState<FormData>(initialFormData);

  const handleFormDataChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const handleImageUploadChange = (images: FileList) => {
    setFormData(prevFormData => ({ ...prevFormData, images: Array.from(images) }));
  }

  const handleLocationChange = (location: string | null | undefined) => {
    if(!location){
      return;
    }
    setFormData(prevFormData => ({ ...prevFormData, location: location}));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Send form data to server here
    console.log(formData);
    setFormData(initialFormData);
  };

  const propertyTypeOptions = [
    { value: 'House', label: 'House' },
    { value: 'Department', label: 'Department' },
    { value: 'Office', label: 'Office' },
    { value: 'Business Premises', label: 'Business Premises' },
    { value: 'Land', label: 'Land' },
    { value: 'Warehouse', label: 'Warehouse' },
    { value: 'Other', label: 'Other' },
  ];

  const antiquityOptions = [ // < 5 years, 5-10, years, 10-15 years, 15-20 years, > 20 years
    { value: 'lessThan5', label: '< 5 years' },
    { value: 'between5and10', label: '5-10 years' },
    { value: 'between10and15', label: '10-15 years' },
    { value: 'between15and20', label: '15-20 years' },
    { value: 'moreThan20', label: '> 20 years' },
  ];

  const transactionTypeOptions = [
    { value: 'Sell', label: 'Sell' },
    { value: 'Rent', label: 'Rent' },
    { value: 'Liquidation Sale', label: 'Liquidation Sale' },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <PropertyInputRow label="Property Title" name="PropertyTitle" value={formData.propertyTitle} onChange={handleFormDataChange} />
      <SelectInputRow label="Property Type" name="propertyType" options={propertyTypeOptions} value={formData.propertyType} onChange={handleFormDataChange} />
      <SelectInputRow label="Antiquity" name="antiquity" options={antiquityOptions} value={formData.antiquity} onChange={handleFormDataChange} />
      <div className="row">
        <div className="col-md-6">
          <SelectInputRow label="Transaction Type" name="transactionType" options={transactionTypeOptions} value={formData.transactionType} onChange={handleFormDataChange} />
        </div>
        <div className="col-md-6">
          <PropertyInputRow label="Price" name="price" value={formData.price} onChange={handleFormDataChange} />
        </div>
      </div>
      <PropertyInputRow label="Bedrooms" name="bedrooms" value={formData.bedrooms} onChange={handleFormDataChange} />
      <PropertyInputRow label="Parking Spots" name="parkingSpots" value={formData.parkingSpots} onChange={handleFormDataChange} />
      <PropertyInputRow label="Lot Size" name="lotSize" value={formData.lotSize} onChange={handleFormDataChange} />
      <PropertyInputRow label="Building Size" name="buildingSize" value={formData.buildingSize} onChange={handleFormDataChange} />
      <ImageInput id="property-images" label="Images" name="images" onChange={handleImageUploadChange} initialImages={[]}/>
      <LocationPicker onLocationSelect={handleLocationChange} />
      <button type="submit" className="btn btn-primary">Submit</button>
      <button type="reset" className="btn btn-secondary">Reset</button>
      <button type="button" className="btn btn-danger">Delete</button>
    </form>
  );

};

export default PropertyForm;