import { FC } from "react";
import { Col, Row } from 'reactstrap';

import PricingTableCard from './PricingTableCard';

import '@inmomatch-app/shared/css/style.css';
import 'styles/Pricing.css';

const PricingTable:FC = () => {
    return (
        <Row id='cards-container'
            className='mx-auto row-cols-md-4 text-center'
        >
            <Col>
                <PricingTableCard
                    name='Prueba'
                    price={0}
                    properties={5}
                    perks={[
                        'Acceso a la plataforma',
                    ]}
                />
            </Col>
            <Col>
                <PricingTableCard
                    name='Básico'
                    price={220}
                    properties={10}
                    perks={[
                        'Acceso a la plataforma',
                        'Soporte técnico gratuito',
                        'Actualizaciones constantes',
                        '1 mes de prueba gratuito',
                    ]}
                />
            </Col>
            <Col>
                <PricingTableCard
                    name='Avanzado'
                    price={350}
                    properties={25}
                    perks={[
                        'Acceso a la plataforma',
                        'Soporte técnico gratuito',
                        'Actualizaciones constantes',
                    ]}
                />
            </Col>
            <Col>
                <PricingTableCard
                    name='Completo'
                    price={550}
                    properties={Number.MAX_SAFE_INTEGER}
                    perks={[
                        'Acceso a la plataforma',
                        'Soporte técnico gratuito',
                        'Actualizaciones constantes',
                    ]}
                />
            </Col>
        </Row>
    )
}

export default PricingTable;