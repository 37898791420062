import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Spinner } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faUser } from "@fortawesome/free-solid-svg-icons";

import { LoginBackground } from "assets";
import { HeaderPortal } from "components";
import { UserContext, UserContextType } from "context";
import { UserDoc } from "interface/UserDoc";

import '@inmomatch-app/shared/css/style.css';
import 'styles/EditProfile.css';

const EditProfile = () => {
    const { userDoc, editUserDoc } = useContext(UserContext) as UserContextType;
    const [updatedUserDoc, setUpdatedUserDoc] = useState<UserDoc>(() => userDoc!)
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const infoChanged = (key: keyof Pick<UserDoc, 'name' | 'lastName' | 'phone'>, newValue: string) => {
        let newUDoc = {...updatedUserDoc}
        newUDoc[key] = newValue;
        setUpdatedUserDoc(newUDoc);
    }

    const submitEditProfile = async () => {
        setLoading(true);
        await editUserDoc(updatedUserDoc);
        setLoading(false);
        navigate('/');
    }

    return (
        <Container fluid className='p-0' id='edit-profile-page'>
            <HeaderPortal />
            <Container
                fluid
                className='p-0 d-flex align-items-center'
                id='background'
                style={{backgroundImage: `url(${LoginBackground})`}}
            >

                <Container 
                    className='bg-light pt-3 pb-2
                        border border-primary border-1 rounded-3
                    '
                    id='form-edit'
                >
                    <Container className='p-0 mb-4'>
                        <Container
                            className='p-0
                                d-flex justify-content-center align-items-center 
                                border border-primary border-3 rounded-circle
                            '
                            id='icon-container'
                        >
                            <FontAwesomeIcon icon={faUser} id='icon' />
                        </Container>
                    </Container>

                    <Form className='px-2'>
                        <FormGroup>
                            <InputGroup>
                                <Input
                                    className='border-primary no-focus border-end-0'
                                    id='name'
                                    name='name'
                                    placeholder='Nombre'
                                    value={updatedUserDoc.name}
                                    onChange={(e) => infoChanged('name', e.target.value)}
                                    type='text'
                                />
                                <InputGroupText
                                    className='border-primary bg-light text-hover'
                                >
                                    <Label for='name' className='unset'>
                                        <FontAwesomeIcon icon={faPenToSquare} 
                                            className='text-primary fs-2'
                                        />
                                    </Label>
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup>
                                <Input
                                    className='border-primary no-focus border-end-0'
                                    id='last-name'
                                    name='last-name'
                                    placeholder='Apellidos'
                                    value={updatedUserDoc.lastName}
                                    onChange={(e) => infoChanged('lastName', e.target.value)}
                                    type='text'
                                />
                                <InputGroupText
                                    className='border-primary bg-light text-hover'
                                >
                                    <Label for='last-name' className='unset'>
                                        <FontAwesomeIcon icon={faPenToSquare} 
                                            className='text-primary fs-2'
                                        />
                                    </Label>
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup>
                                <Input
                                    className='border-primary no-focus border-end-0'
                                    id='number'
                                    name='number'
                                    placeholder='Número de contacto'
                                    value={updatedUserDoc.phone ?? ''}
                                    onChange={(e) => infoChanged('phone', e.target.value)}
                                    type='text'
                                />
                                <InputGroupText
                                    className='border-primary bg-light text-hover'
                                >
                                    <Label for='number' className='unset'>
                                        <FontAwesomeIcon icon={faPenToSquare}
                                            className='text-primary fs-2'
                                        />
                                    </Label>
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Input
                                disabled={true}
                                className='border-primary no-focus'
                                id='admin-name'
                                name='admin-name'
                                placeholder='Nombre de administrador'
                                type='text'
                            />
                        </FormGroup>
                        <Container fluid
                            className='p-0 d-flex justify-content-end align-items-center prevent-select'
                        >
                            <a href='/' id='return'
                                className='me-3 text-decoration-none text-primary'
                                draggable={false}
                            >
                                <span>
                                    Regresar
                                </span>
                            </a>
                            <Button
                                className='text-light no-focus border-0'
                                id='submit'
                                onClick={submitEditProfile}
                            >
                                {   loading ? 
                                    <Spinner />
                                    :
                                    'Guardar'
                                }
                            </Button>
                        </Container>
                    </Form>
                </Container>
            </Container>
        </Container>
    )
}

export default EditProfile;