import { PropertyCard } from "components";
import { HouseDefault } from "assets";

const properties = [];

for(let i=0; i < 6; i++) {
    properties.push(
        <PropertyCard
            key={i}
            title='Titulo de propiedad' 
            image={HouseDefault} //{imagelist[0].src}
            bedrooms={3}
            bathrooms={1.5}
            parking={3}
            price='15,000,000'
            description='Velit quasi laborum aut animi recusandae. Vel explicabo vel ut nobis sed molestiae.'
        />
    )
}

export const GenerateDefaultProperties = (num: number, width?: string): Array<JSX.Element> =>{
    let cards = [];
    for(let i = 0; i < num; i++) {
        cards.push(
            <PropertyCard
                key={i}
                title='Titulo de propiedad' 
                image={HouseDefault}
                bedrooms={3}
                bathrooms={1.5}
                parking={3}
                price='15,000,000'
                width={width}
                description='Velit quasi laborum aut animi recusandae. Vel explicabo vel ut nobis sed molestiae.'
            />
        )
    }
    return cards;
}

export default properties as JSX.Element[];