import { FC } from "react";
import { Container } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import UserDropdown from "./UserDropdown";

import '@inmomatch-app/shared/css/style.css';
import 'styles/Header.css';

interface ProfileComponentProps {
    logged: boolean
}

const ProfileComponent:FC<ProfileComponentProps> = ({logged}) => {
    return (
        <Container className='prevent-select p-0 m-0' id='profile-component'>
        {   logged ?
            <Container
                className='p-0 m-0 d-flex'
            >
                <UserDropdown />
            </Container>
            :
            <a href='login' id='header-link'>
                <Container
                    className='d-flex text-light fs-5 p-0 m-0 justify-content-center align-items-center'
                    id='sign-button'
                >
                    <FontAwesomeIcon icon={faRightToBracket} />
                    <span className='ms-2'>Ingresar</span>
                </Container>
            </a>   
        }
        </Container>
    )
}

export default ProfileComponent;