import { FC } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBath, faBed, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Col, Row } from 'reactstrap';

import { Logo } from 'assets';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import '@inmomatch-app/shared/css/style.css';
import 'styles/Property.css';

interface PropertyCardProps {
    id?: string;
    type?: string;
    title: string;
    image: string;
    bedrooms: number;
    bathrooms: number;
    parking: number;
    price: string;
    description: string;
    width?: string;
    maxWidth?: string;
}

const PropertyCard:FC<PropertyCardProps> = ({id, type, title,image,bedrooms,bathrooms,parking,price,description,width,maxWidth}) => {
    return (
        <Card className='border border-dark border-2 px-3' id='property-card'
            style={{
                minWidth: width ?? '26%',
                maxWidth: maxWidth ?? ''
            }}
        >
            <a href={`/property/${type}/${id}`}
                id='title-link'
                className='text-center text-decoration-none'
            >
                <span className='text-primary fs-3 fw-bold py-1'>
                    {title}
                </span>       
            </a>
            <img src={image} alt='property' id='property-image' draggable={false}
                className='mx-auto border border-3 border-primary user-select-none'
            />
            <Row className='px-2 mt-2'>
                <Col md='7' className='p-0'>
                    <span id='price'className='fs-4 fw-bold'>
                        {price}$ MX
                    </span>
                </Col>
                <Col md='5' className='p-0 user-select-none
                    fw-bold fs-6
                    d-flex justify-content-end gap-1'
                >
                    <span className='d-flex align-items-center gap-1'>
                        <FontAwesomeIcon icon={faBed} className='text-primary'/>
                        {bedrooms}
                    </span>
                    <span className='d-flex align-items-center gap-1'>
                        <FontAwesomeIcon icon={faBath} className='text-primary'/>
                        {bathrooms}
                    </span>
                    <span className='d-flex align-items-center gap-1'>
                        <FontAwesomeIcon icon={faWarehouse} className='text-primary'/>
                        {parking}
                    </span>
                </Col>
            </Row>
            <Row className='px-2 mt-2'>
                {description}
            </Row>
            <Row className='px-2 my-2 d-flex align-items-center'>
                <Col md='4' className='p-0 text-center'>
                    <img src={Logo}
                        id='property-seller-photo'
                        alt='house seller'
                        draggable={false}
                        className='img-fluid mx-auto
                        object-fit-cover
                        border border-primary border-2 rounded-circle'
                    />
                </Col>
                <Col md='8' className='p-0 text-end'>
                    <span className='fs-7 pe-md-4'>
                        Inmobiliaria / Agente
                    </span>
                    <Button color='primary' 
                        className='mt-2 ms-auto
                            text-light fw-bold
                            d-flex justify-content-center align-items-center'
                    >
                        <FontAwesomeIcon icon={faWhatsapp} 
                            className='me-1 fs-2'
                        />
                        <span className='fs-7'>
                            HAGAMOS MATCH
                        </span>
                    </Button>
                </Col>
            </Row>
        </Card>
    )    
}

export default PropertyCard;