import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";

import { UserContext, UserContextType } from "context";
import ImageInput from "components/PropertyForm/ImageInput";
import { ModalProps, PropertyFormData, PropertyObject } from "@inmomatch-app/shared/interface";

import '@inmomatch-app/shared/css/style.css';

const initialFormData: PropertyFormData = {
    propertyType: '',
    adquisitionType: '',
    price: 0,
    bedrooms: 0,
    bathrooms: 0,
    parkingSpots: 0,
    age: '',
    lotArea: 0,
    buildingArea: 0,
    images: [],
    location: '',
    description: ''
};

const propertyFormToFormData = (form: PropertyFormData, propertyType: string): FormData => {
    const formData = new FormData();
    const { images, originalImages, ...formStripped } = form;

    const isEmpty = (value: string | number): boolean => {
        if(typeof value === 'string') return value.length === 0;
        if(typeof value === 'number') return value === 0;
        return true;
    }

    if(form.propertyType === undefined || form.propertyType.length === 0) formData.append('propertyType', propertyType);
    for(const key in formStripped) {
        if(!isEmpty(formStripped[key])) {
            formData.append(key, formStripped[key]);
        }
    }
    images.forEach((image) => {
        formData.append('images', image);
    });

    originalImages?.forEach((image: any) => {
        formData.append('originalImages', image);
    })
    return formData;
}

interface PropertyModalFormProps extends ModalProps {
    propertyType: string;
    editing: boolean;
    property: PropertyObject | null;
}

const PropertyModalForm = ({isOpen, toggle, propertyType, editing, property}: PropertyModalFormProps): JSX.Element => {
    const { uploadProperty, editProperty } = useContext(UserContext) as UserContextType;
    const [loading, setLoading] = useState<boolean>(false);

    const sendFormData = async (formData: FormData) => {
        setLoading(true);
        if(editing) {
            await editProperty(formData);
        }else {
            await uploadProperty(formData);
        }
        toggle();
        setLoading(false);
    }

    return (
        <div>
            <Modal
                centered
                size='xl'
                isOpen={isOpen}
                toggle={toggle}
                backdrop='static'
                keyboard={false}
            >
                <ModalHeader>
                    {editing ? 'Editar' : 'Añadir'} {propertyType}
                </ModalHeader>
                <ModalBody>
                    {
                        loading ?
                        <Container
                            fluid
                            className='d-flex justify-content-center align-items-center'
                            style={{
                                height: '30vh'
                            }}
                        >
                            <Spinner />
                        </Container>
                        :
                        <PropertyForm
                            propertyType={propertyType}
                            toggle={toggle}
                            sendFormData={sendFormData}
                            property={property}
                            editing={editing}
                        />
                    }
                </ModalBody>
            </Modal>
        </div>
    )
}

interface PropertyFormProps {
    propertyType: string;
    sendFormData: (formData: FormData) => void;
    toggle: () => void;
    editing: boolean;
    property: PropertyObject | null;
}

const imagesToFile =async (images: string[]):Promise<File[]> => {
    const files = await Promise.all(images.map(async (image, index) => {
        const res = await fetch(image);
        const img = await res.blob();

        return new File([img], `image_${index}`, {type: 'image/jpeg'});
    }));

    return files;
}

const getInitialFormData = async (initialProperty: PropertyObject | null): Promise<PropertyFormData> => {
    if(!initialProperty) return initialFormData;
    
    let formData: PropertyFormData = {
        _id: initialProperty._id,
        originalImages: initialProperty.images,

        propertyType: initialProperty.propertyType,
        adquisitionType: initialProperty.adquisitionType,
        price: initialProperty.price,
        bedrooms: initialProperty.bedrooms ?? 0,
        bathrooms: initialProperty.bathrooms ?? 0,
        parkingSpots: initialProperty.parkingSpots ?? 0,
        age: initialProperty.age ?? '',
        lotArea: initialProperty.lotArea ?? 0,
        buildingArea: initialProperty.buildingArea ?? 0,
        images: await imagesToFile(initialProperty.images),
        location: initialProperty.location,
        description: initialProperty.description,
    }

    return formData;
}

const PropertyForm = ({propertyType, sendFormData, toggle, editing, property}: PropertyFormProps) => {
    const [formData, setFormData] = useState<PropertyFormData>(() => initialFormData);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if(!property) return setLoading(false);

        getInitialFormData(property)
            .then((initialForm) => {
                setFormData(initialForm);
                setLoading(false);
            })
    },[property]);

    const handleFormDataChange = (name: string, value: string | number | File[]) => {
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    }

    const handleImageUploadChange = (images: FileList) => {
      setFormData(prevFormData => ({ ...prevFormData, images: Array.from(images) }));
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formdata = propertyFormToFormData(formData, propertyType);
        sendFormData(formdata);
    };

    if(loading) return <Spinner />

    return(
        <Form
            onSubmit={handleSubmit}
        >
            <Container
                fluid
                className='p-0'
            >
                <Row 
                    className='p-0'
                >
                    <Col md={7}>
                        <Container
                            fluid
                            className='p-0'
                        >
                            <PropertyInputSelect
                                label='Tipo de Adquisición'
                                id='adquisition-type'
                                name='adquisitionType'
                                options={
                                    [
                                        {label: ''},
                                        {label: 'Venta'},
                                        {label: 'Renta'},
                                        {label: 'Remate'}
                                    ]
                                }
                                valueChange={handleFormDataChange}
                                initialValue={formData.adquisitionType}
                            />
                            <PropertyInputRow
                                label={ 
                                    propertyType === 'Terreno' ?
                                    <>Precio por m<sup>2</sup> ($MXN)</>
                                    :
                                    'Precio ($MXN)'
                                }
                                id='price-property'
                                name='price'
                                numbered={true}
                                limit={15}
                                valueChange={handleFormDataChange}
                                initialValue={(formData.price)?.toString()}
                            />
                            {
                                propertyType === 'Casa' || 
                                propertyType === 'Departamento' ?
                                <>
                                    <PropertyInputRow
                                        label='No. de Recámaras'
                                        id='bedrooms'
                                        name='bedrooms'
                                        numbered={true}
                                        limit={3}
                                        valueChange={handleFormDataChange}
                                        initialValue={(formData.bedrooms)?.toString()}
                                    />
                                    <PropertyInputRow
                                        label='No. de Baños'
                                        id='bathrooms'
                                        name='bathrooms'
                                        numbered={true}
                                        limit={3}
                                        valueChange={handleFormDataChange}
                                        initialValue={(formData.bathrooms)?.toString()}
                                    />
                                </>
                                :
                                null
                            }
                            {
                                propertyType !== 'Terreno' &&
                                propertyType !== 'Bodega' ?
                                <>
                                    <PropertyInputRow
                                        label='No. de Estacionamiento'
                                        id='parking'
                                        name='parkingSpots'
                                        numbered={true}
                                        limit={3}
                                        valueChange={handleFormDataChange}
                                        initialValue={(formData.parkingSpots)?.toString()}
                                    />
                                    <PropertyInputSelect
                                        label='Antigüedad'
                                        id='age'
                                        name='age'
                                        options={
                                            [
                                                {label: ''},
                                                {label: '< 5 años'},
                                                {label: '5-10 años'},
                                                {label: '10-15 años'},
                                                {label: '15-20 años'},
                                                {label: '>20 años'}
                                            ]
                                        }
                                        valueChange={handleFormDataChange}
                                        initialValue={(formData.age)?.toString()}
                                    />
                                </>
                                :
                                null
                            }
                            {
                                propertyType === 'Casa' ||
                                propertyType === 'Terreno' ?
                                <PropertyInputRow
                                    label={<>Terreno (m<sup>2</sup>)</>}
                                    id='lotArea'
                                    name='lotArea'
                                    numbered={true}
                                    limit={5}
                                    valueChange={handleFormDataChange}
                                    initialValue={(formData.lotArea)?.toString()}
                                />
                                :
                                null
                            }
                            {
                                propertyType !== 'Terreno' ?
                                <PropertyInputRow
                                    label={<>Construcción (m<sup>2</sup>)</>}
                                    id='buildingArea'
                                    name='buildingArea'
                                    numbered={true}
                                    limit={5}
                                    valueChange={handleFormDataChange}
                                    initialValue={(formData.buildingArea)?.toString()}
                                />
                                :
                                null
                            }
                            <PropertyInputRow
                                label='Ubicación'
                                id='location'
                                name='location'
                                valueChange={handleFormDataChange}
                                initialValue={formData.location}
                            />
                        </Container>
                    </Col>
                    <Col md={5}>
                        <ImageInput 
                            id="property-images" 
                            label="Imágenes" 
                            name="images" 
                            onChange={handleImageUploadChange}
                            initialImages={formData.images}
                        />
                        <Container
                            fluid
                            className='p-0 m-0'
                        >
                            <FormGroupDescription
                                id='description'
                                label='Descripción'
                                name='description'
                                valueChange={handleFormDataChange}
                                initialValue={formData.description}
                            />
                        </Container>
                        <Container
                            fluid
                            className='p-0 text-end'
                        >
                            <Button
                                color='danger'
                                onClick={toggle}
                                className='me-3'
                            >
                                Cancelar
                            </Button>
                            <Button
                                color='primary'
                                className='text-light'
                                type='submit'
                            >
                                {   
                                    editing ?
                                    'Editar Propiedad'
                                    :
                                    'Subir Propiedad'
                                }
                            </Button>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Form>
    )
}


type PropertyTypeOptions = {
    label: string;
}

type InputProps = {
    id: string;
    label: string | JSX.Element;
    name: string;
    feedback?: string;
    valueChange: (name: string,  value: string | number | File[]) => void;
    initialValue?: string | undefined;
}

interface PropertySelectProps extends InputProps {
    options: PropertyTypeOptions[];
}

interface PropertyInputRowProps extends InputProps {
    numbered?: boolean;
    limit?: number;
}

const PropertyInputSelect = ({label, id, name, feedback, options, valueChange, initialValue}: PropertySelectProps):JSX.Element => {
    const [valid, setValid] = useState<boolean>(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setValid(value.length>0);
        valueChange(name, value);
    }

    return (
        <FormGroup row>
            <Label 
                for={id}
                md={4}
            >
                {label}
            </Label>
            <Col 
                md={8}
                className='ms-auto'
            >
                <Input 
                    id={id}
                    className='no-focus'
                    name={name}
                    type='select'
                    onChange={handleChange}
                    invalid={!valid}
                    onInvalid={(e) => {
                        const input = e.target as HTMLInputElement;
                        input.setCustomValidity('Llene todos los campos')
                    }}
                    value={initialValue ?? ''}
                >
                    {options.map((option, index) => {
                        return(
                            <option key={index}>
                                {option.label}
                            </option>
                        )
                    })}
                </Input>
                <FormFeedback>
                    {feedback ?? 'Seleccione un tipo'}
                </FormFeedback>
            </Col> 
        </FormGroup>
    )
}

const PropertyInputRow = ({id, name, label, feedback, numbered, limit, valueChange, initialValue }:PropertyInputRowProps ): JSX.Element => {
    const [query, setQuery] = useState<string>(() => initialValue ?? '');
    const [valid, setValid] = useState<boolean>(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if(numbered) {
            const numValue = value.replace(/,/g, '');
            if(numValue.length === 0) {
                setQuery('');
            }else if(numValue.length <= (limit ?? Number.MAX_SAFE_INTEGER)) {
                const numberValue = value.replace(/([^\d.])/g, '');
                const formattedValue = Number(numberValue).toLocaleString();
                valueChange(name, Number(numberValue));
                setQuery(formattedValue);
            }
        }else {
            const {name, value} = event.target;
            setQuery(value);
            valueChange(name, value);
        }
        setValid(value.length > 0);
    }

    return (
        <FormGroup row>
            <Label
                for={id}
                md={4}
            >
                {label}
            </Label>
            <Col
                md={8}
                className='ms-auto'
            >
                <Input
                    id={id}
                    className='no-focus'
                    type='text'
                    name={name}
                    onChange={handleChange}
                    value={query}
                    invalid={!valid}
                    onInvalid={(e) => {
                        const input = e.target as HTMLInputElement;
                        input.setCustomValidity('Llene todos los campos')
                    }}
                />
                <FormFeedback>
                    {feedback ?? 'Escriba un valor'}
                </FormFeedback>
            </Col>
        </FormGroup>
    )
}

const FormGroupDescription = ({id, label, name, valueChange, initialValue}: InputProps):JSX.Element => {
    return(
        <FormGroup>
            <Label for={id}>
                {label}
            </Label>
            <Input
                id={id}
                name={name}
                onChange={e => {
                    const {name, value} = e.target;
                    valueChange(name, value);
                }}
                type='textarea'
                className='no-focus'
                onInvalid={(e) => {
                    const input = e.target as HTMLInputElement;
                    input.setCustomValidity('descripcion invalid');
                }}
                value={initialValue ?? ''}
            />
        </FormGroup>
    )
}

export default PropertyModalForm;