import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";

import { PropertyObject } from "@inmomatch-app/shared/interface";

import '@inmomatch-app/shared/css/style.css';
import 'styles/Portal.css';

type PortalTableOptionsProps = {
    property: PropertyObject;
    handleEdit: (property: PropertyObject) => void;
    handleDelete: (property: PropertyObject) => void;
}

const PortalTableOptions = ({property, handleEdit, handleDelete}: PortalTableOptionsProps): JSX.Element => {
    const handleView = () => {
        window.location.href = (`/property/${property.propertyType.toLowerCase()}/${property._id}`);
    }

    return (
        <div
            id='icons-options'
            className='d-flex align-items-center fs-5'
        >
            <FontAwesomeIcon id='view'   className='me-2' icon={faEye} onClick={handleView}/>
            <FontAwesomeIcon id='edit'   className='mx-2' icon={faPencil} onClick={() => handleEdit(property)} />
            <FontAwesomeIcon id='delete' className='ms-2' icon={faTrash} onClick={() => handleDelete(property)}/>
        </div>
    )
}

export default PortalTableOptions;