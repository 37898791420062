import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { FirebaseAppProvider } from 'reactfire';

import { UserProvider } from 'context';
import { firebaseConfig } from 'firebaseConfig';
import { PageLoader } from 'pages';
import { Router } from 'router/Router';

import reportWebVitals from './reportWebVitals';

import 'index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
    <Suspense fallback={<PageLoader />}>
      <UserProvider>
        <Router />
      </UserProvider>
    </Suspense>
  </FirebaseAppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
